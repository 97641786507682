import React from "react";
import PropTypes from "prop-types";

export default class RadioButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: props.selected,
        };
        this.handleClick = (value) => {
            this.setState({ selected: value });
            this.props.onChange(value);
        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="buttons has-addons">
                    {this.props.options.map((node, key) => {
                        let buttonClass = "button";
                        if (this.props.small) {
                            buttonClass += " is-small has-text-weight-semibold";
                        }
                        if (node[1] == this.state.selected) {
                            buttonClass += " is-success";
                        }
                        return (
                            <button className={buttonClass} key={key} onClick={() => this.handleClick(node[1])}>
                                {node[0]}
                            </button>
                        );
                    })}
                </div>
            </React.Fragment>
        );
    }
}

RadioButton.propTypes = {
    options: PropTypes.array,
    selected: PropTypes.any,
    onChange: PropTypes.func,
    small: PropTypes.bool,
};
