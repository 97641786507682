import React from "react";
import PropTypes from "prop-types";
import PricingChecklist from "../components/PricingChecklist";
import RadioButton from "../components/RadioButton";

import gpResearcher from "../images/gp-researcher.png";

export default class PricingResearcher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quota: 1,
            description: "For researchers who require quick execution and no quotas.",
            list: [
                {
                    title: "No job queue wait time",
                    description:
                        "With the scalable cloud infrastructure, your jobs will run right away without having to wait for their turn in the queue.",
                },
                {
                    title: "Access to Pro workflows with sample data",
                    description: `Pro workflows are ready-to-use pipelines that are guaranteed to run on a Galaxy Pro instance.
                                  They are based on published protocols, come with a manual, and sample data.`,
                },
                {
                    title: "Large memory, many-CPU, and GPU machines",
                    description: `Jobs can be allocated large quantities of resources, including up to 4TB or memory for those assemblies.
                                  GPU nodes are also available for tools that require them.`,
                },
                {
                    title: "Guaranteed 99% uptime",
                    description: `Galaxy Pro Researcher comes with a Service Level Agreement guarantee of 99%,
                                  which is equivalent of no more than 7hrs12mins of downtime per month.`,
                },
                {
                    title: "Community help",
                    description: `Help with analysis, tool selection, workflow development is available from the active Galaxy community
                                  at help.galaxproject.org.`,
                },
                {
                    title: "Cancel any time",
                    description: "With monthly plans, there is no need for long-term committment.",
                },
            ],
        };
        this.handleQuota = (value) => this.setState({ quota: value });
    }
    render() {
        let currentPrice = this.props.checked ? this.props.monthPrice : this.props.monthPrice * this.props.monthFactor;
        currentPrice = currentPrice * this.state.quota;
        return (
            <React.Fragment>
                <div className="box pricingbox">
                    <PricingChecklist
                        image={gpResearcher}
                        price={currentPrice.toFixed(0)}
                        list={this.state.list}
                        description={this.state.description}
                        optionTitle="Multiple plans for storage and processing needs"
                        optionDesc="<div>
                            Each plan comes with a combination of storage and monthly processing hours. Processing hours
                            represent how many resources your jobs are consuming, computed as
                            <div>
                                <code>max(RAM/CPU, 4)/4 * CPU * Runtime</code>
                            </div>
                            <div>
                                For example, a job that requires 8GB RAM, uses 2 CPUs, and runs for 1hr will consume 2
                                processing hours. Job runtime is calculated with 1 second granularity.
                            </div>
                        </div>"
                        recommended="Individuals"
                        button="Contact Us"
                        href="/contact/"
                        option={
                            <RadioButton
                                options={[
                                    ["500GB & 500hr", 1],
                                    ["1TB & 1,000hr", 1.9],
                                    ["2TB & 2,000hr", 3.3],
                                ]}
                                selected={1}
                                onChange={this.handleQuota}
                                small={true}
                            />
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

PricingResearcher.propTypes = {
    checked: PropTypes.bool,
    monthFactor: PropTypes.number,
    monthPrice: PropTypes.number,
};
