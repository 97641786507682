import React from "react";
import PropTypes from "prop-types";

import { BiCaretUpCircle as IconCollapse } from "react-icons/bi";
import { BiCaretDownCircle as IconExpand } from "react-icons/bi";
import { BiCheckCircle as IconInfo } from "react-icons/bi";

export default class Collapsible extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
        };
        this.handleClick = (value) => {
            this.setState({ collapsed: value });
        };
        this.hasDescription = () => !!this.props.description;
    }
    render() {
        if (this.state.collapsed) {
            return (
                <div className="collapsible">
                    {this.hasDescription() && (
                        <span className="collapsible-header" onClick={() => this.handleClick(false)}>
                            <IconExpand className="feature-list-icon mr-2 has-text-primary is-size-5" />
                            <span className="feature-list-title">{this.props.title}</span>
                        </span>
                    )}
                    {!this.hasDescription() && (
                        <span>
                            <IconInfo className="feature-list-icon mr-2 is-size-5" />
                            <span className="feature-list-title">{this.props.title}</span>
                        </span>
                    )}
                </div>
            );
        } else {
            return (
                <div className="collapsible">
                    <span className="collapsible-header collapsible-active" onClick={() => this.handleClick(true)}>
                        <IconCollapse className="feature-list-icon mr-2 has-text-primary is-size-5" />
                        <span className="feature-list-title">{this.props.title}</span>
                    </span>
                    <p className="box is-shadowless is-size-6 mb-2">
                        <small dangerouslySetInnerHTML={{ __html: this.props.description }}></small>
                    </p>
                </div>
            );
        }
    }
}

Collapsible.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
};
