import { Link } from "gatsby";
import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Collapsible from "../components/Collapsible";

import Pricing from "../components/Pricing";

import gridAnalysis from "../images/tools.png";
import gridCollab from "../images/collaboration.png";
import gridPipelines from "../images/workflow.png";
import gridInfra from "../images/gp-saas.png";
import gridReporting from "../images/history.png";

const UseCaseBox = (props) => (
    <div className="box">
        <Link to={props.url}>
            <h2 className="title is-4">{props.title}</h2>
            <p>{props.blurb}</p>
        </Link>
    </div>
);

UseCaseBox.propTypes = {
    title: PropTypes.string,
    blurb: PropTypes.string,
    url: PropTypes.string,
};

const WhatIsBox = (props) => (
    <div className="box whatisbox">
        <h2 className="title is-4 has-text-dark has-text-centered">{props.title}</h2>
        <article className="media is-vertical-center">
            <div className="media-left">
                <figure className="image is-128x128">
                    <img src={props.image} alt={props.title} />
                </figure>
            </div>
            <div className="media-content">
                <div className="content">
                    <p className="is-3">{props.blurb}</p>
                </div>
            </div>
        </article>
    </div>
);

WhatIsBox.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    blurb: PropTypes.string,
};

const faqs = [
    {
        question: "What is Galaxy?",
        answer: `Galaxy is software for analyzing genomic data, from a web browser or via an API. Galaxy software
             is being used by tens of thousands of researchers worldwide, has been cited more than 10,000 times,
             and includes thounsads of the most popular bioinformatics tools.`,
    },
    {
        question: "How is Galaxy Pro different from Galaxy?",
        answer: `Galaxy Pro is a managed installation of Galaxy on the cloud. Galaxy Pro comes with no usage quotas
             or queue wait times, offers enhanced security, and validated workflows and tools so your analysis
             can proceed without the need to manage software.`,
    },
    {
        question: "What type of data can I analyze with Galaxy Pro?",
        answer: `Next Generation Sequencing (NGS) data, including RNA-Seq, ChIP-Seq, variant calling, single cell,
             long-read data, and more. Just about any command line tool (eg, R, custom scripts, proprietary tools)
             can be incorporated into Galaxy Pro allowing it to be used for a variety of analyses.`,
    },
    {
        question: "Does Galaxy Pro require any coding?",
        answer: `No. Galaxy Pro is accessed over the internet through a web browser with a graphical interface.
             If you would like to write custom scripts or analyses, Galaxy Pro offers a REST API that can be
             used for automating analyses.`,
    },
    {
        question: "Why should I sign up for Galaxy Pro?",
        answer: `With Galaxy Pro you do not need to install or manage the Galaxy software, tools, or computers,
             allowing you to focus on analyzing data and obtaining results. Galaxy Pro installations can
             be customized to include specific tools or tool versions that meet your analysis needs. There are
             no usage quotas on Galaxy Pro, making it possible to perform analyses quickly. Galaxy Pro can also
             be configured with custom computers to support large memory jobs (eg, assemblies) and GPUs (eg, basecalling).`,
    },
    {
        question: "How do I get access to Galaxy Pro?",
        answer: `Galaxy Pro is a subscription based service. Once signed up, you will be provided with a URL where
             you can access Galaxy Pro via a web browser. Contact us for more information.`,
    },
    {
        question: "How much data or how many samples can I analyze with the available subscription plans?",
        answer: `For example, an RNA-seq run using the Pro workflow requires about 115GB of storage and consumes
                 about 12 processing hours when run against a human sample (2 samples in triplicate with ~10GB per replicate
                 (5 per side of paired end)). The same workflow consumes about 20GB of storage and
                 5 processing hours when analyzing <i>D. melanogaster</i> while a run with <i>C. elegans</i> consumes about
                 10GB of storage and 2.5 processing hours.`,
    },
    {
        question: "I'm a student. Is there an educational discount?",
        answer: `Send us an email to info@galaxyworks.io with what you're trying to do and we'll work with you.`,
    },
    {
        question: "Where does Galaxy Pro run? Can it run on my local infrastructure?",
        answer: "Galaxy Pro is a cloud based platform. We do not support local installations of Galaxy Pro.",
    },
    {
        question: "What are Pro workflows?",
        answer: `Galaxy Pro allows multiple tools to be linked into a seamless pipeline and execute them automatically in a
             sequence. Pro Workflows is a library of custom-crafted workflows that have been operationally validated and
             are ready to use. Each Pro Workflow comes with documentation and test data, allowing you to start using them
             quickly with confidence that the results produced are correct. On average, it takes 3-6 months to create a
             Pro Workflow so our growing library allows you to get to results sooner. These best-practice workflows are
             also continuously refined and kept up-to-date with the latest research and methods.`,
    },
    {
        question: "Can custom tools be added to Galaxy Pro?",
        answer: `To Custom instances, yes. To the Researcher, no. However, if the tool you need is published in the Galaxy ToolShed
             by the IUC, let us know and we'll do our best to add it.`,
    },
    {
        question: "What type of tools can be added to Galaxy Pro Custom?",
        answer: `Just about any command line Linux tool can be added, including tools written in R, tools communicating with
             external services, or visualizations.`,
    },
    {
        question: "What if I need help?",
        answer: `There a abundant training materials on a variety of topcis available for the Galaxy platform and available at
             training.galaxyproject.org. General questions about the usage of the Galaxy software should be directed to
             help.galaxyproject.org. Specific questions about the Galaxy Pro service, should be directed to help@galaxyworks.io.
             Professional services and dedicated support with workflow design and analysis are available only for the
             Galaxy Pro Custom service.`,
    },
];

const PricingPage = () => (
    <Layout>
        <SEO title="Pricing" />
        <section className="hero is-primary is-medium">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <h1 className="title alternative is-1">Elevate your Data Analysis. Start Free.</h1>
                    <h2 className="subtitle">
                        Unlimited Tool and Workflow Executions. No Software or Infrastructure Management required.
                    </h2>
                </div>
            </div>
        </section>
        <section className="hero is-light">
            <div className="hero-body">
                <div className="container">
                    <Pricing />
                </div>
            </div>
        </section>
        <section className="hero is-primary">
            <div className="hero-body">
                <div className="container">
                    <h2 className="title is-2 has-text-centered">Features at a Glance</h2>
                    <div className="columns">
                        <div className="column">
                            <WhatIsBox
                                image={gridAnalysis}
                                title="Exploratory Analysis"
                                blurb="Hundreds of certified tools for total flexibility."
                            />
                        </div>
                        <div className="column">
                            <WhatIsBox
                                image={gridPipelines}
                                title="Easy-to-Run Pipelines"
                                blurb="Use optimized Pro workflows out of the box, or customize with lab developed pipelines."
                            />
                        </div>
                        <div className="column">
                            <WhatIsBox
                                image={gridCollab}
                                title="Collaborate"
                                blurb="Share your work, collaborate on analyses, leverage a community."
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <WhatIsBox
                                image={gridReporting}
                                title="Support &amp; Training"
                                blurb="Access dedicated support, help, and custom training for maximum efficiency."
                            />
                        </div>
                        <div className="column">
                            <WhatIsBox
                                image={gridInfra}
                                title="Managed Software"
                                blurb="Galaxy Pro is handled for you on scalable cloud infrastructure, so all you need is an internet browser."
                            />
                        </div>
                        <div className="column">
                            <div
                                style={{
                                    backgroundColor: "rgba(255, 255, 255, 0)",
                                    height: "100%",
                                    border: "2px dashed white",
                                }}
                                className="box"
                            >
                                <div className="content has-text-white has-text-centered">
                                    <h2 className="title is-4">Customized for You</h2>
                                    <br />
                                    <p>
                                        Our flexible platform can integrate just about any tool, data, or visualization.
                                    </p>
                                    <p>Imagine the possibilities!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="hero is-light">
            <div className="hero-body">
                <div className="container">
                    <h2 className="title is-2 has-text-centered">Frequently Asked Questions</h2>

                    {faqs.map((node, key) => (
                        <Collapsible key={key} title={node.question} description={node.answer} />
                    ))}
                </div>
            </div>
        </section>
    </Layout>
);

export default PricingPage;
