import React from "react";

import RadioButton from "../components/RadioButton";

import PricingChecklist from "../components/PricingChecklist";
import PricingResearcher from "../components/PricingResearcher";

import gpCustom from "../images/gp-custom.png";
import gpFree from "../images/gp-free.png";

const monthFactor = 1.2;
const monthResearcher = 208.333;
const monthCustom = 3000;

export default class Pricing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: true,
        };
        this.handleChecked = (value) => {
            this.setState({ checked: value });
        };
    }
    render() {
        const customPrice = this.state.checked ? monthCustom : monthCustom * monthFactor;
        return (
            <React.Fragment>
                <RadioButton
                    options={[
                        ["Billed annually (save 17%)", true],
                        ["Billed monthly", false],
                    ]}
                    selected={true}
                    onChange={this.handleChecked}
                />
                <div className="columns">
                    <div className="column">
                        <div className="box pricingbox">
                            <PricingChecklist
                                list={[
                                    {
                                        title: "Public infrastructure",
                                        tooltip: "Use publicly funded resource infrastructure.",
                                    },
                                    { title: "Hundreds of tools" },
                                    { title: "Storage and compute quotas" },
                                    { title: "Community help" },
                                ]}
                                description={"For everyone who wants to get started with Galaxy quickly."}
                                image={gpFree}
                                price={0}
                                recommended="Everyone"
                                button="Get Started"
                                href="https://usegalaxy.org"
                            />
                        </div>
                    </div>
                    <div className="column">
                        <PricingResearcher
                            checked={this.state.checked}
                            monthFactor={monthFactor}
                            monthPrice={monthResearcher}
                        />
                    </div>
                    <div className="column">
                        <div className="box pricingbox">
                            <PricingChecklist
                                list={[
                                    {
                                        title: "Custom tools",
                                        description: `The tool panel can be customized to your needs to include tools
                                                      available in the Galaxy ToolShed or your custom-developed ones.`,
                                    },
                                    {
                                        title: "Scientific guidance and dedicated support",
                                        description: `A team of domain experts will help you get the most from your data, including advice on
                                                      which tools to use, job parameters, workflow design, and more.`,
                                    },
                                    {
                                        title: "Unlimited users",
                                        description: "Add as many lab mates or colleagues as you would like.",
                                    },
                                    {
                                        title: "Starting at 4TB storage",
                                        description: `4TB of collective storage and 4,000 monthly processing hours are included with each Custom instance and additional capacity is available.`,
                                    },
                                    {
                                        title: "Infrastructure isolation and enhanced privacy",
                                        description:
                                            "Each Custom instance is completely isolated providing an added layer of data protection.",
                                    },
                                    {
                                        title: "Support for multi-omic analyses",
                                        description:
                                            "Bring your single-cell or long-read sequencing data too, with tool packages available for analyzing diverse data together.",
                                    },
                                    {
                                        title: "Institutional login with MFA security",
                                        description:
                                            "SAML, LDAP, or OIDC-based single sign-on available, including multi-factor authentication option.",
                                    },
                                    {
                                        title: "Institution or company branding",
                                        description:
                                            "Your company logo in the Galaxy Pro masthead, including an option to use your brand colors, and a custom URL.",
                                    },
                                    {
                                        title: "Access to Pro workflows with sample data",
                                        description: `Pro workflows are ready-to-use pipelines that are guaranteed to run on a Galaxy Pro instance.
                                                      They are based on published protocols, come with a manual, and sample data.`,
                                    },
                                    {
                                        title: "Large memory, many-CPU, and GPU machines",
                                        description: `Jobs can be allocated large quantities of resources, including up to 4TB or memory for those assemblies.
                                                      GPU nodes are also available for tools that require them.`,
                                    },
                                    {
                                        title: "Guaranteed 99% uptime",
                                        description: `Galaxy Pro Researcher comes with a Service Level Agreement guarantee of 99%,
                                                      which is equivalent of no more than 7hrs12mins of downtime per month.`,
                                    },
                                ]}
                                description={"For teams or users that need to analyze private data using custom tools."}
                                image={gpCustom}
                                price={customPrice}
                                recommended="Labs"
                                button="Contact Us"
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
