import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Collapsible from "../components/Collapsible";

function numberWithCommas(x) {
    if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return 0;
    }
}

export default class PricingChecklist extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <img src={this.props.image} alt="Customized" style={{ height: "50px" }} />
                <div className="pricing-recommendation subtitle is-size-6 has-text-dark mt-4 mb-0">
                    Recommended for <span className="has-text-weight-bold">{this.props.recommended}</span>
                </div>
                <span className="title has-text-primary">
                    <span className="is-size-3">${numberWithCommas(this.props.price)}</span>
                    <span className="is-size-6">&nbsp;per Month</span>
                </span>
                <Link
                    className="button is-warning is-fullwidth mt-5 has-text-weight-bold"
                    to={this.props.href || "/contact"}
                >
                    {this.props.button}
                </Link>
                <div className="mt-4">
                    <div className="title is-size-5 pricing-description pt-5">{this.props.description}</div>
                    <div className="is-divider" data-content="Includes" />
                    <div className="media is-vertical-center mt-4">
                        <ul>
                            <li>
                                {this.props.option && (
                                    <div>
                                        <Collapsible
                                            title={this.props.optionTitle}
                                            description={this.props.optionDesc}
                                        />
                                        <span className="feature-list-title"></span>
                                        <div className="ml-5 mt-0 mb-2">{this.props.option}</div>
                                    </div>
                                )}
                            </li>
                            {this.props.list.map((node, key) => (
                                <li key={key}>
                                    <Collapsible title={node.title} description={node.description} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

PricingChecklist.propTypes = {
    list: PropTypes.array,
    description: PropTypes.string,
    option: PropTypes.object,
    optionTitle: PropTypes.string,
    optionDesc: PropTypes.string,
    image: PropTypes.image,
    price: PropTypes.price,
    recommended: PropTypes.string,
    button: PropTypes.string,
    href: PropTypes.string,
};
